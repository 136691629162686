
import RenderInstructionRuleCollection from "@/components/settings/render-instructions/RenderInstructionRuleCollection.vue"
import RenderInstructionService from "@/services/RenderInstructionService"
import WindowingsValue from "@/components/settings/render-instructions/windowings/WindowingsValue.vue"
import { ref, reactive, onMounted } from "vue"
import RenderInstructionImport from "@/components/settings/render-instructions/RenderInstructionImport.vue"

export default {
  name: "ColorAdjustmentsCollection",
  components: { WindowingsValue, RenderInstructionRuleCollection, RenderInstructionImport },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    openImport: {
      type: Boolean,
      default: false,
    },
    orgIdToSelect: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup(props: any) {
    const service = new RenderInstructionService("riwindowings")
    const additionalFields = reactive({ contrast: 1, brightness: 1 })
    const columns = reactive([
      { field: "contrast", header: "Contrast", width: "5rem", icon: "ContrastCircle" },
      { field: "brightness", header: "Brightness", width: "6rem", icon: "Brightness6" },
    ])
    const collection = ref()

    onMounted(() => {
      collection.value.riImportDialog = props.openImport
    })

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.riImportDialog = false
      collection.value.reload()
    }

    function showRiImportDialog() {
      collection.value.riImportDialog = true
    }

    return {
      additionalFields,
      service,
      columns,
      collection,
      closeDialogs,
      showRiImportDialog,
    }
  },
}
